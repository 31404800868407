<template>
  <div ref="headerRef" class="header-auth" :class="{ open: openPopup }">
    <div class="header-auth__top" @click="openPopup = !openPopup">
      <div
        class="header-auth__img"
        :style="
          currentUser?.image
            ? { 'background-image': `url(${currentUser?.image})` }
            : `background-image: url(${NO_AVATAR_URL()})`
        "
      ></div>

      <div class="header-auth__info">
        <div class="header-auth__name">{{ currentUser?.name }}</div>
        <div class="header-auth__mail">{{ currentUser?.email }}</div>
      </div>
      <div class="header-auth__arr">
        <svg class="icon ic-arrow-down" width="11" height="6">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-arrow-down"></use>
        </svg>
      </div>
    </div>
    <!--    TODO: Add item.label to translation list-->
    <div class="header-auth__popup" @click.stop>
      <router-link v-for="item in menuItems" :key="item.label" :to="item.to" :class="item.class">
        {{ trans(item.label) }}
      </router-link>
      <div class="header-auth__exit" @click.prevent="logoutUser">
        <svg class="icon ic-logout" width="16" height="16">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-logout"></use>
        </svg>
        {{ trans('Logout') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { trans, TranslationKey } from '@/common/language/translator';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useAuthStore } from '@/store/modules/auth';
import useClickOutside from '@/common/clickOutside';
import { useRouter } from 'vue-router';
import { NO_AVATAR_URL } from '@/common/lib/getAvatar';

interface MenuItem {
  label: TranslationKey;
  to: string;
  class: string;
  condition?: boolean;
}

export default defineComponent({
  name: 'HeaderAuth',
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const openPopup = ref(false);
    const imageUpdateKey = computed(() => authStore.imageUpdateKey);
    const currentUser = computed(() => authStore.getCurrentUser);
    const avatarUrl = computed<string>(() => currentUser.value?.image ?? NO_AVATAR_URL);

    const headerRef = ref<HTMLElement | null>(null);
    const menuItems = computed(() => {
      const items: MenuItem[] = [
        // TODO: Hidden - Add when the feature is ready
        // {
        //   label: 'My shifts',
        //   to: '/user/settings/profile',
        //   class: 'header-auth__item',
        // },
        {
          label: 'My profile',
          to: '/user/settings/profile',
          class: 'header-auth__item'
        }
      ];
      return items;
    });

    onMounted(() => {
      useClickOutside(headerRef, () => (openPopup.value = false));
    });

    watch(
      () => authStore.isLoggedIn,
      newVal => {
        authStore.setLoggedInState(newVal);
      },
      { immediate: true }
    );

    const logoutUser = async () => {
      try {
        authStore.logout();
        await router.push('/auth/login');
      } catch (error) {
        console.error(trans('Failed to logout'), error);
      }
    };

    return {
      avatarUrl,
      imageUpdateKey,
      menuItems,
      openPopup,
      currentUser,
      authStore,
      headerRef,
      logoutUser,
      trans
    };
  },
  methods: {
    NO_AVATAR_URL() {
      return NO_AVATAR_URL;
    }
  }
});
</script>

<style scoped lang="scss"></style>
