import enTranslations from './locales/en.json';
import heTranslations from './locales/he.json';
import { refreshFn } from '@/common/lib/refresh';

const translations = {
  en: enTranslations as Record<string, string>,
  he: heTranslations as Record<string, string>
} as const;

export type Languages = keyof typeof translations;

export const LanguageNames: { [key in Languages]: string } = {
  en: 'English',
  he: 'Hebrew'
} as const;

let defaultLanguage: Languages = 'he';

export let currentLanguage: Languages = defaultLanguage;
let currentTranslation = translations[currentLanguage];

export function setLanguage(language: Languages) {
  if (!(language in translations)) {
    language = defaultLanguage;
  }
  if (currentLanguage === language) {
    return;
  }
  currentLanguage = language;
  currentTranslation = translations[language];
  document
    .getElementsByTagName('html')[0]
    .classList[currentLanguage === 'he' ? 'add' : 'remove']('rtl');
  localStorage.setItem('language', language);
  refreshFn?.();
}

export function translate(
  key: keyof (typeof translations)[Languages],
  variables?: Record<string, string>
): string {
  return (
    (variables
      ? currentTranslation[key]?.replace(
          /%(\w+)%/g,
          (_: string, varName: string) => variables?.[varName] || _
        )
      : currentTranslation[key]) ?? key
  );
}

export type TranslationKey = keyof (typeof translations)[Languages];

export function trans(key: TranslationKey): string {
  return currentTranslation[key] ?? key;
}

/**
 * Check for missing or untranslated keys.
 * TODO: This function should be removed in production.
 * @param language The language to check translations for.
 * @returns An array of keys that are missing or untranslated in the specified language.
 */
export function findMissingOrUntranslatedKeys(language: Languages = defaultLanguage): string[] {
  const untranslatedKeys: string[] = [];

  const defaultTranslation = translations.en;
  const currentLangTranslation = translations[language];

  for (const key in defaultTranslation) {
    // Check if the key is missing or untranslated (same value as in English)
    if (!currentLangTranslation[key] || currentLangTranslation[key] === defaultTranslation[key]) {
      untranslatedKeys.push(key);
    }
  }

  return untranslatedKeys;
}
const missingKeys = findMissingOrUntranslatedKeys('he');
if (missingKeys.length) {
  console.log('Missing or untranslated keys in Hebrew:', missingKeys);
} else {
  console.log('All keys are translated.');
}
