<template>
  <div class="popup-overlay" v-if="active"></div>
  <div class="popup-code" v-if="active">
    <div class="popup-code__success">
      <!--      <div>-->
      <!--        <img src="../../assets/img/success.svg" alt="" style="width:135px;">-->
      <!--      </div>-->

      <div class="popup-code__title">
        {{ translate('Auth.hi_message', { name: currentUser?.name }) }},
      </div>
      <div class="popup-code__text">
        {{ trans('Auth.successfully_login1') }}
        <br />
        {{ trans('Auth.successfully_login2') }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { trans, translate } from '@/common/language/translator';
import { computed, defineComponent } from 'vue';
import { IUser } from '@/types/interfaces';
import { getAuthStore } from '@/store';

export default defineComponent({
  name: 'PaymentRequired',
  setup() {
    const authStore = getAuthStore();
    const currentUser = authStore.currentUser as IUser;
    const currentBusiness = authStore.currentBusiness;
    const active = computed(() => false);
    // todo: implement missing billing logic
    return {
      currentUser,
      currentBusiness,
      active,
      translate,
      trans
    };
  }
});
</script>

<style scoped></style>
