import { defineStore } from 'pinia';
import {
  Column,
  Filter,
  IPagination,
  LeadHistorySection,
  LeadTableData,
  LeadTableHeaderNames
} from '@/types/interfaces';
import axios from '@/services/axios';
import { Lead_URL } from '@/store/modules/auth';
import { trans } from '@/common/language/translator';
import { ApiResponse } from '@/types/api_interface';

type GetLeadsResponse = {
  success: boolean;
  leads: LeadTableData[];
  pagination: IPagination;
};

export const useLeadStore = defineStore({
  id: 'lead-store',
  state: () => ({
    leadTableColumns: [
      { key: 'name', text: trans('Lead name'), status: true },
      { key: 'phone', text: trans('Phone'), status: true },
      { key: 'email', text: trans('Email'), status: true },
      { key: 'status', text: trans('Status'), status: true },
      { key: 'source', text: trans('Source'), status: true },
      { key: 'date', text: trans('Date'), status: true },
      { key: 'meeting', text: trans('Meeting Status'), status: true },
      { key: 'utm', text: trans('UTM'), status: true }
      // { key: 'star', text: trans('Rating rate'), status: true }
    ] as Column[],
    leadTableHeaders: [
      { key: 'name', title: trans('Name'), status: true },
      { key: 'phone', title: trans('Phone'), status: true },
      { key: 'email', title: trans('Email'), status: true },
      { key: 'status', title: trans('Status'), status: true },
      { key: 'source', title: trans('Source'), status: true },
      { key: 'date', title: trans('Date & Time'), status: true },
      { key: 'meeting', title: trans('Meeting'), status: true },
      { key: 'utm', title: trans('UTM'), status: true }
      // { key: 'star', isSVG: true, svgName: 'ic-star-fill', status: true }
    ] as LeadTableHeaderNames[],
    filteredLeads: {} as Record<string, LeadTableData[]>,
    selectedLeads: [] as string[],
    currentLead: null as LeadTableData | null,
    selectAll: false as boolean,
    openPopupId: null as string | null,
    historyTab: LeadHistorySection.All,
    openLeadTransferPopup: false,
    openLeadActionsPanel: false,
    openCallBackModal: false,
    openMeetingModal: false,
    allLeads: false,
    leadToCallBack: null as LeadTableData | null,
    redirectedToLeads: false
  }),
  getters: {
    getLeadTableColumns: state => {
      return state.leadTableColumns;
    },
    getLeadTableHeaders: state => {
      return state.leadTableHeaders;
    },
    getFilteredLeads: state => {
      return state.filteredLeads;
    },
    getSelectedLeads: state => {
      return state.selectedLeads;
    },
    getOpenPopupId: state => {
      // New getter
      return state.openPopupId;
    },
    getHistoryTab: state => {
      return state.historyTab;
    },
    getOpenLeadTransferPopup: state => {
      return state.openLeadTransferPopup;
    },
    getCurrentLead: state => {
      return state.currentLead;
    }
  },
  actions: {
    async getLeadsOfCampaign(
      campaignIds: string[],
      page = 1,
      limit = 10,
      filters: Filter[] = [],
      search: string = '',
      all: boolean = false
    ): Promise<GetLeadsResponse> {
      try {
        const response = await axios.get(Lead_URL, {
          params: {
            campaignId: campaignIds, // Send the entire array as a query parameter
            page: String(page),
            limit: String(limit),
            filters: encodeURIComponent(JSON.stringify(filters)),
            search,
            all
          }
        });

        if (response.data.success) {
          if (response.data.response.leads && response.data.response.pagination) {
            return {
              success: true,
              leads: response.data.response.leads,
              pagination: response.data.response.pagination
            };
          } else {
            return { success: true, leads: [], pagination: {} };
          }
        } else {
          return { success: false, leads: [], pagination: {} };
        }
      } catch (e) {
        console.log(e);
        return { success: false, leads: [], pagination: {} };
      }
    },
    async deleteLeads(leadIds: string[], campaignId: string): Promise<ApiResponse> {
      let response: ApiResponse;
      try {
        response = await axios.post(`${Lead_URL}/delete`, {
          leadIds,
          campaignId
        });
        if (response.data.success) {
          return { data: { success: true, message: response.data.message } };
        } else {
          return { data: { success: false, message: response.data.message } };
        }
      } catch (e) {
        console.log(e);
        return { data: { success: false, message: 'Error deleting lead' } };
      }
    },
    reset() {
      this.$reset();
    },
    setLeadTableColumns(columns: Column[]) {
      this.$patch({ leadTableColumns: columns });
    },
    setSelectedLeads(leads: string[]) {
      this.$patch({ selectedLeads: leads });
    },
    setOpenPopupId(id: string | null) {
      this.$patch({ openPopupId: id });
    },
    setHistoryTab(tab: LeadHistorySection) {
      this.$patch({ historyTab: tab });
    },
    setOpenLeadTransferPopup(open: boolean) {
      this.$patch({ openLeadTransferPopup: open });
    },
    setOpenLeadActionsPanel(open: boolean) {
      this.$patch({ openLeadActionsPanel: open });
    },
    setOpenCallBackModal(open: boolean) {
      this.$patch({ openCallBackModal: open });
    },
    setLeadToCallBack(lead: LeadTableData | null) {
      this.$patch({ leadToCallBack: lead });
    },
    setCurrentLead(lead: LeadTableData | null) {
      this.$patch({ currentLead: lead });
    },
    setAllLeads(all: boolean) {
      this.$patch({ allLeads: all });

      const campaignHeader = {
        key: 'campaignName',
        title: trans('Campaign'),
        status: true
      };
      const campaignColumn = {
        key: 'campaignName',
        text: trans('Campaign'),
        status: true
      };

      if (all) {
        if (!this.leadTableHeaders.some(header => header.key === 'campaignName')) {
          this.leadTableHeaders.push(campaignHeader);
        }
        if (!this.leadTableColumns.some(column => column.key === 'campaignName')) {
          this.leadTableColumns.push(campaignColumn);
        }
      } else {
        this.leadTableHeaders = this.leadTableHeaders.filter(
          header => header.key !== 'campaignName'
        );
        this.leadTableColumns = this.leadTableColumns.filter(
          column => column.key !== 'campaignName'
        );
      }
    }
  },
  persist: false
});
