import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bd859122"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popup-overlay"
}
const _hoisted_2 = {
  key: 1,
  class: "popup-code"
}
const _hoisted_3 = { class: "popup-code__success" }
const _hoisted_4 = { class: "popup-code__title" }
const _hoisted_5 = { class: "popup-code__text" }
const _hoisted_6 = { class: "auth__content" }
const _hoisted_7 = { class: "profile-card-info__item" }
const _hoisted_8 = { class: "profile-card-info__label" }
const _hoisted_9 = { class: "input-wrapper" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "auth__btn" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMsg = _resolveComponent("ErrorMsg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate('Auth.hi_message', { name: _ctx.currentUser?.email })) + ", ", 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.trans('Please provide your name to continue')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", {
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
                ref: "form"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.trans('Full name')), 1),
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "name",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                      placeholder: _ctx.trans('Enter your full name')
                    }, null, 8, _hoisted_10), [
                      [_vModelText, _ctx.name]
                    ]),
                    _createVNode(_component_ErrorMsg, { name: "name" })
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("button", {
                    class: "btn",
                    disabled: !_ctx.isValidForm || _ctx.disabledSubmit
                  }, _toDisplayString(_ctx.trans('Save')), 9, _hoisted_12)
                ])
              ], 544)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}