<template>
  <div v-if="openSuccess" class="popup-code">
    <div class="popup-code__success">
      <div class="popup-code__icon">
        <img :src="iconSrc" alt="" style="width: 135px" />
      </div>

      <div class="popup-code__title">
        {{
          translate(titleMessage, {
            name: currentUser?.name ?? '',
            mail: currentUser?.email ?? ''
          })
        }},
      </div>
      <div class="popup-code__text">
        {{ textMessage1 }}
        <br />
        {{ textMessage2 }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { trans, translate } from '@/common/language/translator';
import { computed, defineComponent } from 'vue';
import { IUser } from '@/types/interfaces';

export default defineComponent({
  name: 'SuccessMessage',
  props: {
    currentUser: {
      type: Object as () => IUser,
      required: true
    },
    openSuccess: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator: (value: string) => ['register', 'login'].includes(value)
    }
  },
  setup(props) {
    const titleMessage = computed(() => {
      return props.type === 'register' ? 'RegisterPage.hey' : 'Auth.hi_message';
    });

    const textMessage1 = computed(() => {
      return props.type === 'register'
        ? trans('RegisterPage.successfully_register1')
        : trans('Auth.successfully_login1');
    });

    const textMessage2 = computed(() => {
      return props.type === 'register'
        ? trans('RegisterPage.successfully_register2')
        : trans('Auth.successfully_login2');
    });

    const iconSrc = computed(() => {
      return props.type === 'register'
        ? '../../assets/img/success.svg'
        : '../../assets/img/success.svg'; // Adjust paths if needed
    });

    return {
      translate,
      trans,
      titleMessage,
      textMessage1,
      textMessage2,
      iconSrc
    };
  }
});
</script>

<style scoped></style>
